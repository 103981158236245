
import { Component, Vue } from 'vue-property-decorator'
import Add from './Add.vue'

@Component({
  name: 'maintenanceDay',
  components: { Add }
})

export default class extends Vue {
  private info = {
    projectId: '',
    dailyDate: ''
  }

  private addShow = false
  private id = ''

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private tableData = []

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.loadData()
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios
      .get(
        this.$apis.maintenanceDaily.selectYhProjectDailyByPage,
        {
          ...this.info,
          page: this.page,
          size: this.size
        }
      )
      .then((res) => {
        if (res) {
          this.total = res.total || 0
          this.tableData = res.list || []
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  isToday (today: any) {
    const date = this.$dayjs().format('YYYY-MM-DD')
    if (today === date) {
      return true
    } else {
      return false
    }
  }

  // 新增
  onAdd () {
    // console.log('新增')
    // this.$router.push({ name: 'addTeams' })
    this.addShow = true
  }

  // 查看
  onDetail (id: string, projectId: string, dailyDate: string) {
    this.$router.push({
      path: '/maintenanceDaily/details',
      query: {
        dailyId: id,
        projectId,
        dailyDate
      }
    })
  }

  onEdit (id: string, projectId: string, dailyDate: string) {
    this.$router.push({
      path: '/maintenanceDaily/edit',
      query: {
        dailyId: id,
        projectId,
        dailyDate
      }
    })
  }

  // 删除
  onDelete (dailyId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        this.$axios.post(this.$apis.maintenanceDaily.deleteYhProjectDaily, { dailyId }).then(() => {
          this.$message.success('删除成功')
          this.onSearch()
        }).finally(() => {
          this.loading = false
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  goDailyTemplate () {
    this.$router.push({
      path: '/maintenanceDaily/DailyTemplate'
    })
  }
}
