
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'

@Component({
  name: 'maintenanceDay'
})
export default class extends Vue {
  @Prop() readonly addShow!: boolean

  private info = {
    // 基础信息
    projectId: '',
    dailyDate: ''
  }

  private BindingProjectList = [] as any // 绑定项目模板

  private submitShow = false

  private rules= {
    projectId: [
      { required: true, message: '请选择项目名称', trigger: 'change' }
    ],
    dailyDate: [
      { required: true, message: '请输入日报日期', trigger: 'change' }
    ]
  }

  private pickerOptions = {
    disabledDate (time: any) {
      const now = (new Date()).getTime()
      return time.getTime() > now
    }
  }

  get projectList () {
    return this.$store.state.projectList
  }

  @Watch('addShow')
  isAddShow (val: string) {
    if (val) {
      this.getBindingProjectTemptate() // 查询已绑定的项目模板
    }
  }

  getBindingProjectTemptate () {
    this.$axios.get(this.$apis.maintenanceDaily.selectYhProjectDailyTemplateList).then(res => {
      this.BindingProjectList = res.filter((item: any) => {
        return item.templateId
      }).map((k: any) => {
        return k.projectId
      })
      // console.log('this.BindingProjectList', this.BindingProjectList)
    })
  }

  saveForm () {
    // console.log('info', this.info);
    (this.$refs.formData as ElForm).validate(valid => {
      if (valid) {
        let isHaveTemplate = true
        isHaveTemplate = this.BindingProjectList.find((item: any) => {
          return item === this.info.projectId
        })
        if (!isHaveTemplate) {
          this.$message('该项目未配置模板，请先配置模板')
          return
        }

        this.$axios.get(this.$apis.maintenanceDaily.selectYhProjectDailyDateList, {
          projectId: this.info.projectId
        }).then(res => {
          // console.log('绑定的日期', res)
          let flag = false
          flag = res.some((item: any) => {
            return item === this.info.dailyDate
          })
          if (flag) {
            this.$message('当前日期已有日报')
          } else {
            // console.log('通过')
            this.$router.push({
              path: '/maintenanceDaily/add',
              query: {
                projectId: this.info.projectId,
                dailyDate: this.info.dailyDate
              }
            })
          }
        })
      }
    })
  }

  handleClose () {
    this.$emit('update:addShow', false)
    this.info = {
      // 基础信息
      projectId: '',
      dailyDate: ''
    };
    (this.$refs.formData as ElForm).resetFields()
  }
}
